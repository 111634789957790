<template>
  <div>
    <div class="d-sm-none">
      <div
        ref="sitecontentfootersmall"
        class="Site-footer "
        :style="dynamicsmall"
      >
        <div class="container text-center">
          <div class="row my-2">
            <div class="col-12 my-2 text-center">
              <span>
                <a class="footerlink" target="_blank" href="https://putincoin.org">
                Web
                </a>
              </span>

              <span class="ms-5">
                <a class="footerlink" target="_blank" href="https://putincoin.info">
                Explorer
                </a>
              </span>
              <span class="ms-5">
                <router-link class="footerlink" to="/faq">FAQ</router-link>
              </span>
              <div style="height: 5px;"></div>
              <span class="ms-5" style="margin-left: 0px !important;">
                <span class="footerlink">
                <a href="mailto:help@putincoin.to?subject=PUTinCoin%20Bridge%20-%20Support%20Request&body=Dear%20Bridge%20team%2C%0D%0A%0D%0AI%20need%20support%20for%20following%20wrap%2Funwrap%3A%0D%0A%0D%0A%0D%0ATransaction%20ID%20(PUT%20or%2Fand%20BSC)%3A%20%0D%0A%0D%0AMy%20MetaMask%20address%3A%20%0D%0A%0D%0AIf%20it's%20about%20a%20wrap%20-%20Wrapping%20PUT%20address%20generated%20by%20bridge%3A%20%0D%0A%0D%0AIf%20it's%20about%20an%20unwrap%20-%20My%20target%20PUT%20address%3A%20%0D%0A%0D%0AWrapping%2FUnwrapping%20amount%3A%20%0D%0A%0D%0A%0D%0AMy%20issue%3A%20" target="_blank">help@putincoin.to</a></span>
              </span>
            </div>

            <div class="col-12 my-1 text-center">
              <a target="_blank" href="https://github.com/PutinCoinPUT">
                <img src="../assets/github.svg" width="16" height="16" style="margin-left:10px" />
              </a>

              <a target="_blank" href="https://twitter.com/coin_put">
                <img src="../assets/twitter.svg" width="16" height="16" style="margin-left:10px" />
              </a>

              <a target="_blank" href="https://t.me/putincoinput">
                <img src="../assets/telegram.svg" width="16" height="16" style="margin-left:10px" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- sm view or larger: -->

    <div class="d-none d-sm-block">
      <div
        ref="sitecontentfooter"
        class="Site-footer "
        :style="dynamicbigfooter"
      >
        <div class="container text-center">
          <div class="row my-0 py-2">
            <div class="col-sm-9 text-start">
              <span class="ms-5" style="margin-left: 1rem !important;">
                <a class="footerlink" target="_blank" href="https://putincoin.org">
                Web
                </a>
              </span>

              <span class="ms-5" style="margin-left: 1rem !important;">
                <a class="footerlink" target="_blank" href="https://putincoin.info">
                Explorer
                </a>
              </span>

              <span class="ms-5" style="margin-left: 1rem !important;">
                <router-link class="footerlink" to="/faq">FAQ</router-link>
              </span>

              <span class="ms-5" style="margin-left: 1rem !important;">
                <span class="footerlink">
                <a href="mailto:help@putincoin.to?subject=PUTinCoin%20Bridge%20-%20Support%20Request&body=Dear%20Bridge%20team%2C%0D%0A%0D%0AI%20need%20support%20for%20following%20wrap%2Funwrap%3A%0D%0A%0D%0A%0D%0ATransaction%20ID%20(PUT%20or%2Fand%20BSC)%3A%20%0D%0A%0D%0AMy%20MetaMask%20address%3A%20%0D%0A%0D%0AIf%20it's%20about%20a%20wrap%20-%20Wrapping%20PUT%20address%20generated%20by%20bridge%3A%20%0D%0A%0D%0AIf%20it's%20about%20an%20unwrap%20-%20My%20target%20PUT%20address%3A%20%0D%0A%0D%0AWrapping%2FUnwrapping%20amount%3A%20%0D%0A%0D%0A%0D%0AMy%20issue%3A%20" target="_blank">help@putincoin.to</a>
                </span>
              </span>
            </div>

            <div class="col-sm-3 text-end">
              <a target="_blank" href="https://github.com/PutinCoinPUT">
                <img src="../assets/github.svg" width="16" height="16" style="margin-left:10px" />
              </a>

              <a target="_blank" href="https://twitter.com/coin_put">
                <img src="../assets/twitter.svg" width="16" height="16" style="margin-left:10px" />
              </a>

              <a target="_blank" href="https://t.me/putincoinput">
                <img src="../assets/telegram.svg" width="16" height="16" style="margin-left:10px" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { debounce } from "@/helpers.js";

export default {
  components: {
    FontAwesomeIcon,
  },

  props: {
    innerwidth: Number,
    innerheigth: Number,
  },

  data() {
    return {
      clientHeight: 0, //heigth of browser
      heightAppfooter: 0,
      heightAppfootersmall: 0,
      deboucedGetDimension: null,
      resizeObserver: null,
    };
  },

  mounted() {
    this.getDimensions();

    this.deboucedGetDimension = debounce(this.getDimensions, 500);

    this.resizeObserver = new ResizeObserver(() => {
      this.getDimensions();
    });
    this.resizeObserver.observe(document.body);
  },

  unmounted() {
    this.resizeObserver.unobserve(document.body);
  },

  computed: {
    dynamicbigfooter() {
      if (this.clientHeight < this.innerheigth) {
        //does not fit in window
        return {
          width: this.innerwidth + "px",
        };
      }

      return {
        width: this.innerwidth + "px",
        top:
          Math.min(this.clientHeight, this.innerheigth) -
          this.heightAppfooter +
          "px",
      };
    },

    dynamicsmall() {
      if (this.clientHeight < this.innerheigth) {
        //does not fit in window
        return {
          width: this.innerwidth + "px",
        };
      }

      return {
        width: this.innerwidth + "px",
        top:
          Math.min(this.clientHeight, this.innerheigth) -
          this.heightAppfootersmall +
          "px",
      };
    },
  },

  methods: {
    getDimensions() {
      this.clientHeight = document.documentElement.clientHeight;

      this.heightAppfooter = this.$refs.sitecontentfooter.offsetHeight;
      this.heightAppfootersmall = this.$refs.sitecontentfootersmall.offsetHeight;
      // console.log(
      //   "footer: ",
      //   this.innerheigth,
      //   this.innertop,

      //   this.heightAppfooter,
      //   this.heightAppfootersmall
      // );
    },
  },
};
</script>

<style lang="scss" scoped>
.Site-footer {
  background-color: #e5c300;
  color: #000;
  position: absolute;
  &.TestBottom {
    background-color: #8b1e8f;
  }
}
.footerlink {
  color: #000;
  text-decoration: none;
}
</style>
